
import AlertMessage from '@nextory/components/src/AlertMessage'
import NxInput from '@nextory/components/src/form/input'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    NxInput,
    AlertMessage,
  },

  props: {
    query: {
      type: Object,
      default() {
        return { cb: undefined, add_token: false }
      },
    },
  },
  data() {
    return {
      identifier: undefined,
      password: undefined,
      alertLevel: '',
      alertMessage: '',
      alertShow: false,
    }
  },
  validations: {
    identifier: {
      required,
    },
    password: {
      required,
    },
  },
  methods: {
    setIdentifier(newIdentifier) {
      this.identifier = newIdentifier
    },
    setPassword(newPassword) {
      this.password = newPassword
    },
    validateForm() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const data = new FormData()
        data.append('email', this.identifier)
        data.append('password', this.password)
        this.$auth
          .loginWith('nxScheme', { data })
          .then(response => {
            this.$router.push(this.localePath('/search'))
          })
          .catch(error => {
            this.alertLevel = 'danger'
            this.alertShow = true
            this.alertMessage =
              error.response?.data?.title ||
              this.$t('global.error.unexpected_error')
          })
      }
    },
  },
}
